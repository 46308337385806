import Store from '@/store'
import _ from 'lodash'

import MobileDetect from 'mobile-detect'
import helpers from '@/helpers'

import { INTEGRATIONS } from '@/constants'

const MD = new MobileDetect(window.navigator.userAgent)

function middlewarePipeline(context, middleware, index) {
  const nextMiddleware = middleware[index]

  if (!nextMiddleware) {
    return context.next
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1)

    nextMiddleware({ ...context, next: nextPipeline })
  }
}

export const MiddlewarePlugin = (to, from, next, router) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store: Store,
    router,
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
}

export const middlewareGroup = (middlewareList, routes) => {
  let setMid = (route, list) => {
    _.set(
      route,
      'meta.middleware',
      _.concat(list, _.get(route, 'meta.middleware', []))
    )

    if (route.children)
      for (let i = 0; i < route.children.length; i++)
        setMid(route.children[i], route.meta.middleware)

    return route
  }

  return routes.map(e => setMid(e, middlewareList))
}

/**
 * MIDDLEWARES
 */

export const projectExistMid = ({ to, next, store }) => {
  const { projectId } = to.params;
  const project = store.getters['Projects/getProjectById'](projectId);

  if (project) {
    return next();
  }

  store
    .dispatch('Projects/getProject', { projectId: to.params.projectId })
    .then(() => {
      next()
    })
    .catch(error => {
      // TODO: Special error page
      // if(error.status == 404) {
      //   console.warn('404')
      // }

      next({
        name: 'notFound',
      })
    })
}

export const checklistExistMid =  ({ to, next, store }) => {
  const id = to.params.checklistId;

  const checklist = store.getters['Checklists/getChecklistById'](id);

  if (checklist) {
    next();
    return;
  }

  store
    .dispatch('Checklists/getChecklist', { id })
    .then(() => {
      next()
    })
    .catch(() => {
      next({
        name: 'notFound',
      })
    })
}

export const logMid = ({ to, next }) => {
  if (process.env.NODE_ENV == 'development') {
    debug(`[>] ${to.name}`)
  }

  return next()
}

export const projectsViewMid = ({ to, next }) => {
  let name = Store?.state?.App?.viewListProjects || 'projectsKanban',
    query = to.query

  switch (name) {
    case 'list':
      name = 'projectsList'
      break

    case 'kanban':
      name = 'projectsKanban'
      break
  }

  return next({ name, query })
}

export const supportingDevice = ({ next }) => {
  const platformAccess = ['iOS', 'AndroidOS']

  if (helpers.checkMobileDevice(MD)) {
    if (platformAccess.includes(MD.os())) return next()
    else window.location.href = process.env.VUE_APP_SUPPORTING_DEVICES_LINK
  } else
    return next({
      name: 'home',
    })
}

export const mobileMid = ({ next }) => {
  if (helpers.checkMobileDevice(MD)) next()
  else
    return next({
      name: 'home',
    })
}

export const desktopMid = ({ next }) => {
  if (helpers.checkMobileDevice(MD)) window.location = '/sign-in'
  else return next()
}

export const authMid = ({ next }) => {
  store.commit('User/UPDATE_AUTH')

  if (!localStorage.getItem('br_token'))
    return next({
      name: 'signIn',
    })

  next()
}

export const unauthMid = ({ next }) => {
  store.commit('User/UPDATE_AUTH')

  if (localStorage.getItem('br_token'))
    return next({
      name: 'home',
    })

  return next()
}

export const clearAuthMid = ({ to, next }) => {
  debug(`[>!] clear auth: ${to.name}`)

  localStorage.removeItem('br_token')
  store.commit('User/UPDATE_AUTH')

  next()
}

export const rememberProjectChatMid = ({ to, next }) => {
  localStorage.setItem(
    'lastProjectChat',
    JSON.stringify({
      projectId: to.params.projectId,
      chatId: to.params.chatId,
    })
  )

  next()
}

export const checklistItemExistMid = ({ to, next, store }) => {
  const checklistId = to.params.checklistId;
  const itemId = to.params.checklistItemId;

  const checklistItem = store.getters['Checklists/getChecklistItemOrTaskByIds']({
    checklistId,
    itemId,
  })

  if (checklistItem) {
    next()
    return
  }

  store
    .dispatch('Checklists/getChecklistItem', {
      checklistId,
      itemId,
    })
    .then(() => {
      next()
    })
    .catch(error => {
      // TODO: Special error page
      // if(error.status == 404) {
      //   console.warn('404')
      // }

      next({
        name: 'notFound',
      })
    })
}

export const integrationExistMid = ({ to, next }) => {
  if (Object.values(INTEGRATIONS).includes(to.params.integrationSlug)) {
    next()
  } else {
    next({
      name: 'marketplace'
    })
  }
}